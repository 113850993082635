export default {
  backgroundColour: 'brandOne100',
  footerColor: 'brandOne20',
  footerLinksBorderColor: 'brandOne80',
  footerPaddingTop: 'fluidXxLarge',
  footerTextDecoration: 'none',
  includeInverseLogo: true,
  includeSecondaryNavOuter: false,
  includeSecondaryNav: false,
};
