import { useEffect, useState } from 'react';
import { speciesReference } from '../const/speciesReference';
import { SpeciesReferenceItem } from '../types/global';

export const useSpeciesInfo = (
  speciesId?: string
): SpeciesReferenceItem | null => {
  // Initialize state with the correct value immediately
  const [speciesInfo, setSpeciesInfo] = useState<SpeciesReferenceItem | null>(
    () => {
      if (speciesId && speciesReference[speciesId]) {
        return speciesReference[speciesId];
      }
      return null;
    }
  );

  // Update state if speciesId changes
  useEffect(() => {
    if (speciesId && speciesReference[speciesId]) {
      setSpeciesInfo(speciesReference[speciesId]);
    } else {
      setSpeciesInfo(null);
    }
  }, [speciesId]);

  return speciesInfo;
};

export default useSpeciesInfo;
