import React, { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeColorsKeys } from '../../styles/tokens/colors';
import tokens from './tokens';

// Interface
export interface DividerProps {
  inverse?: boolean;
  color?: ThemeColorsKeys;
}

// Helper Function to Determine Border Color
const getBorderColor = (
  colors: Record<string, string>,
  color?: ThemeColorsKeys,
  inverse?: boolean
): string => {
  if (inverse) {
    return colors[tokens.borderColorInverse];
  }
  if (color) {
    return colors[color];
  }
  return colors[tokens.borderColor];
};

// Styles
export const StyledDivider = styled.hr<DividerProps>`
  ${({ theme: { colors }, color, inverse }) => css`
    border: none; /* Reset all borders */
    border-bottom: 1px solid ${getBorderColor(colors, color, inverse)};
  `};
`;

// JSX
export const Divider: FC<DividerProps> = ({ inverse, color, ...rest }) => (
  <StyledDivider inverse={inverse} color={color} {...rest} />
);

export default Divider;
// test
