import { shade, tint } from 'polished';

type FullColorTones<Key extends string> = Record<
  `${Key}120` | `${Key}100` | `${Key}80` | `${Key}50` | `${Key}20` | `${Key}10`,
  string
>;

type LimitedColorTones<Key extends string> = Record<
  `${Key}100` | `${Key}10`,
  string
>;

export function getColorTones<Key extends string>(
  color: string,
  key: Key
): FullColorTones<Key>;

export function getColorTones<Key extends string>(
  color: string,
  key: Key,
  options: { only100and10: true }
): LimitedColorTones<Key>;

export function getColorTones<Key extends string>(
  color: string,
  key: Key,
  options?: { only100and10?: boolean }
): FullColorTones<Key> | LimitedColorTones<Key> {
  if (options?.only100and10) {
    const tones = {
      [`${key}100`]: color,
      [`${key}10`]: tint(0.9, color),
    } as LimitedColorTones<Key>;
    return tones;
  }
  const tones = {
    [`${key}120`]: shade(0.2, color),
    [`${key}100`]: color,
    [`${key}80`]: tint(0.2, color),
    [`${key}50`]: tint(0.5, color),
    [`${key}20`]: tint(0.8, color),
    [`${key}10`]: tint(0.9, color),
  } as FullColorTones<Key>;
  return tones;
}
