import { ThemeColorsKeys } from '@agria/theme/src/styles/tokens/colors';

interface ThemeConfig {
  backgroundColor: ThemeColorsKeys;
  color: ThemeColorsKeys;
  includeInverseFocus: boolean;
}

const themeConfig: ThemeConfig = {
  backgroundColor: 'utilityWhite',
  color: 'brandOne100',
  includeInverseFocus: false,
};

export default themeConfig;
