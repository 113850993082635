import { useStaticQuery, graphql } from 'gatsby';

interface UseSpeciesResponse {
  id: string;
  name: string;
}
export function useSpecies(): UseSpeciesResponse[] {
  const {
    allPawsSpecies,
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          allowedSpecies
        }
      }
      allPawsSpecies {
        nodes {
          id: remoteId
          name
        }
      }
    }
  `);

  if (!siteMetadata?.allowedSpecies) {
    return allPawsSpecies.nodes;
  }
  // Restrict access to just allowed species
  return allPawsSpecies.nodes.filter((s: UseSpeciesResponse) =>
    siteMetadata?.allowedSpecies?.includes(s.name)
  );
}
