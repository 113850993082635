import { inputTokens } from '@agria/theme/src/const/tokens';
import { colors } from './colors';

const borders = {
  card: `solid 1px ${colors.brandOne100}`,
  default: `solid ${inputTokens.borderSize} ${
    colors[inputTokens.borderDefault]
  }`,
  valid: `solid 2px ${colors[inputTokens.borderValid]}`,
  focus: `${colors.systemFocus}`,
  disabled: `solid 2px ${colors.brandOne50}`,
  error: `solid 2px ${colors.systemError}`,
} as const;

export default borders;

export type ThemeBorders = typeof borders;
export type ThemeBordersKeys = keyof typeof borders;
