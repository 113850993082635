import React from 'react';
import { Global, css } from '@emotion/react';
import { globalStyleTokens } from '@agria/theme/src/const/tokens';
import { useBackgroundColor } from '../hooks/useBackgroundColor';
import { useResponsiveType } from '../hooks/useResponsiveType';
import { colors } from './tokens';

export const GlobalStyle = () => (
  <Global
    styles={css`
      *,
      *::before,
      *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
      }

      *:focus-visible {
        ${globalStyleTokens.focusVisible
          ? `box-shadow: ${globalStyleTokens.focusVisible}; outline: none;`
          : `outline: none; box-shadow: ${colors.systemFocus}`};
      }

      /* html {
        scroll-behavior: smooth;
      } */

      html,
      body {
        height: 100%;
      }

      body {
        -webkit-font-smoothing: antialiased;
      }

      img,
      picture,
      video,
      canvas,
      svg {
        display: block;
        max-width: 100%;
      }

      input,
      button,
      textarea,
      select {
        font: inherit;
      }

      p,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        overflow-wrap: break-word;
      }

      #root,
      #__next {
        isolation: isolate;
      }

      ul,
      ol {
        list-style: none;
      }

      a {
        color: currentColor;
      }

      a[href^='tel:'] {
        text-decoration: ${globalStyleTokens.telephoneLinkUnderline};
      }

      strong {
        font-family: ${globalStyleTokens.strongFontFamily};
      }

      address {
        font-style: normal;
      }

      button {
        cursor: pointer;
        background: none;
        color: currentColor;
      }

      // Selection

      ::selection {
      }

      // Body

      body {
        ${useResponsiveType('bodyMedium')}
        ${useBackgroundColor('utilityWhite')}
        min-height: 100vh;
        width: 100%;
        min-width: 320px;

        &.is-hidden,
        &.prevent-body-scroll {
          overflow: hidden !important;
        }
      }

      // Hide onetrust floating button on mob
      #ot-sdk-btn-floating {
        display: none;
      }
    `}
  />
);

export default GlobalStyle;
