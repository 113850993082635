import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';
import { ImageProps } from '@agria/theme/src/components/Image/Image';
import { Stack } from '@agria/theme/src/components/stack/Stack';
import { Typography } from '@agria/theme/src/components/typography/Typography';
import { MiniLoader } from '@agria/theme/src/components/miniLoader/MiniLoader';
import { useBackgroundColor } from '@agria/theme/src/hooks/useBackgroundColor';
import tokens from './tokens';

// Interface

export interface GlobalLoaderProps {
  heading?: string;
  subHeading?: string;
  image?: ImageProps['src'];
}

// Styles

const StyledGlobalLoader = styled.div`
  ${({ theme: { space, layers } }) => css`
    ${useBackgroundColor(tokens.backgroundColor)}
    display: grid;
    height: 100vh;
    inset: 0;
    justify-content: center;
    max-height: 100vh;
    padding: ${space.fluidMedium};
    place-items: center;
    position: fixed;
    text-align: center;
    z-index: ${layers.modalOverlay};

    * {
      text-align: center;
    }
  `};
`;

const StyleGlobalLoaderBody = styled.div`
  ${({ theme: { space } }) => css`
    padding: ${space.fluidMedium};
  `}
`;

const StyledStack = styled(Stack)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const GlobalLoader: FC<GlobalLoaderProps> = ({
  heading,
  subHeading,
  image,
}) => (
  <StyledGlobalLoader data-test="global-loader">
    <StyleGlobalLoaderBody>
      <StyledStack gap="fluidLarge">
        <Typography variant="headingMedium">
          {heading || 'Saving details'}
        </Typography>
        {image && <MiniLoader image={image} />}
        {subHeading && (
          <Typography variant="headingSmall">{subHeading}</Typography>
        )}
      </StyledStack>
    </StyleGlobalLoaderBody>
  </StyledGlobalLoader>
);

export default GlobalLoader;
