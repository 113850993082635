import { useCallback } from 'react';
import { isBrowser } from '../utils/isBrowser';

interface useDataLayerPushReturn {
  dataLayerPush: any;
}

export const useDataLayerPush = (): useDataLayerPushReturn => {
  const dataLayerPush = useCallback((data: Record<string, any>) => {
    if (isBrowser && window.dataLayer) {
      // if (data?.funnel_stage) {
      //   console.log(
      //     `[GA] dataLayerPush - ${data.funnel_stage} ${data.interaction_type}: `
      //   );
      // }
      // console.log(
      //   `dataLayerPush - ${data.funnel_stage} ${data.interaction_type}: `,
      //   data
      // );
      // console.log(`dataLayerPush --------------------`);

      window.dataLayer.push({
        ...data,
      });
    }
  }, []);

  // test

  return {
    dataLayerPush,
  };
};
