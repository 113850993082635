import { ThemeColorsKeys } from '@agria/theme/src/styles/tokens/colors';

interface ThemeConfig {
  backgroundColor: ThemeColorsKeys;
}

const themeConfig: ThemeConfig = {
  backgroundColor: 'brandOne100',
};

export default themeConfig;
