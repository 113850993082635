export default {
  anchorColor: 'brandOne100',
  anchorHoverColor: 'brandTwo100',
  ulColor: 'brandOne100',
  tableBorderColor: 'brandOne80',
  oddRowBackgroundColor: 'brandOne10',
  thBackgroundColor: 'brandOne20',
  tableBorderRadius: 'none',
  tableMobileStyles: false,
};
