import React, { FC } from 'react';
import { isBrowser } from '@agria/utils';
import { Link } from '../link/Link';

interface CookiePreferenceButtonProps {
  buttonLabel: string;
  noButtonPadding?: boolean;
}

export const CookiePreferenceButton: FC<CookiePreferenceButtonProps> = ({
  buttonLabel,
  noButtonPadding,
}) => {
  const handleOpenCookiePreferences = () => {
    if (isBrowser) {
      if (window.OneTrust && window.OneTrust.ToggleInfoDisplay) {
        window.OneTrust.ToggleInfoDisplay(); // Open OneTrust preference center
      }
    }
    // No need to handle CookieYes here since the class will trigger it automatically
  };

  // Return a Link component with the class applied by default
  return (
    <Link
      label={buttonLabel}
      onClick={handleOpenCookiePreferences}
      noButtonPadding={noButtonPadding}
      className="cky-banner-element" // Add the CookieYes class by default
    />
  );
};

export default CookiePreferenceButton;
