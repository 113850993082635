import { brandColors } from './brand';
import { accentColors } from './accent';
import { neutralColors } from './neutral';
import { systemColors } from './system';
import { utilityColors } from './utility';
import { otherColors } from './other';
import { getColorTones } from '../../../utils';
import { brandToneColors } from './brandTones';

export const accentToneColors = {
  // Accent one
  ...(accentColors.accentOne && {
    ...getColorTones(accentColors.accentOne, 'accentOne'),
  }),

  // Accent two
  ...(accentColors.accentTwo && {
    ...getColorTones(accentColors.accentTwo, 'accentTwo'),
  }),

  // Accent three
  ...(accentColors.accentThree && {
    ...getColorTones(accentColors.accentThree, 'accentThree'),
  }),

  // Accent four
  ...(accentColors.accentFour && {
    ...getColorTones(accentColors.accentFour, 'accentFour'),
  }),
} as const;

// Main colours

export const colors = {
  transparent: 'transparent',
  ...neutralColors,
  ...systemColors,
  ...brandColors,
  ...brandToneColors,
  ...accentColors,
  ...accentToneColors,
  ...utilityColors,
  ...otherColors,
} as const;

export default colors;

export type ThemeColors = typeof colors;
export type ThemeColorsKeys = keyof typeof colors;
