import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Circle: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.5 16C1.5 7.98546 7.98546 1.5 16 1.5c8.0145 0 14.5 6.48546 14.5 14.5 0 8.0145-6.4855 14.5-14.5 14.5-8.01454 0-14.5-6.4855-14.5-14.5Zm1.71364 0c0 7.0523 5.73409 12.7864 12.78636 12.7864 7.0523 0 12.7864-5.7341 12.7864-12.7864 0-7.05227-5.7341-12.78638-12.7864-12.78638C8.94773 3.21362 3.21364 8.94773 3.21364 16Z"
      clipRule="evenodd"
    />
  </Icon>
);
