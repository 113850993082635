import { ThemeSpaceKeys } from "@agria/theme/src/styles/tokens/space";

interface TokenProps {
	borderRadius: ThemeSpaceKeys;
}

export const tokens: TokenProps = {
	borderRadius: "xxxSmall",
};

export default tokens;
