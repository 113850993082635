export const globalStyleTokens = {
  telephoneLinkUnderline: 'none',
  focusVisible: false,
  strongFontFamily: 'inherit',
};

export const inputTokens = {
  borderDefault: 'brandOne120',
  borderValid: 'brandTwo50',
  borderSize: '2px',
};

export const pageTokens = {
  borderRadius: 'medium',
  includeSummaryWord: true,
  includeAddAPet: false,
  useHeroHeading: false,
  useAnnualWord: false,
};
